/* ========================================================================
 * DOM-based Routing
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
	var Kundcenter = {
		// All pages
		common: {
			init: function () {
				header.init($);
			},
			finalize: function () {
				autoComplete.init($);
				selecter.init($);
				getPostsBasedOnViews.init($);
				slider.init($);
			},
		},
		single_faq: {
			init: function () {
				countPageView.init($);
			},
		},
	};

	/**
	 * The routing fires all common scripts, followed by the page specific scripts.
	 * Add additional events for more control over timing e.g. a finalize event
	 */

	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Kundcenter;

			funcname = funcname === undefined ? 'init' : funcname;

			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(
				document.body.className.replace(/-/g, '_').split(/\s+/),
				function (i, classnm) {
					UTIL.fire(classnm);
					UTIL.fire(classnm, 'finalize');
				}
			);

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		},
	};

	$('body').on('attempt-form-autofill', function () {
		if (Tulo.user_data) {
			$('.js-autofill-email input').val(Tulo.user_data.contact_email || '');
			$('.js-autofill-customerNumber input').val(
				Tulo.user_data.customer_number || ''
			);
			$('.js-autofill-displayName input').val(
				Tulo.user_data.display_name || ''
			);
			$('.js-autofill-firstName input').val(Tulo.user_data.first_name || '');
			$('.js-autofill-lastName input').val(Tulo.user_data.last_name || '');
			$('.js-autofill-mobileNumber input').val(
				Tulo.user_data.mobile_number || ''
			);
		}
	});

	// Load Events
	$(document).ready(UTIL.loadEvents);

	// debug logged in user
	window.addEventListener('load',function(){
		var userDetailsName = document.querySelector('.user-details .user-details__username');
		var userDetailsNumber = document.querySelector('.user-details .user-details__customer-number a');
		if(userDetailsName){console.log(userDetailsName.textContent);}
		if(userDetailsNumber){console.log(userDetailsNumber.textContent);}
	});

})(jQuery); // Fully reference jQuery after this point.
